import React from 'react';
import bcg from '../img/bcg-4.jpg';

function SectionFour() {
  return (
    <div className='py-40 px-4 '>
      <p className='text-center text-[#ED6D28] mb-2'>
        Nie czekaj... zgłoś zdarzenie!
      </p>
      <p className='text-center text-4xl'>
        Jeśli Ty lub ktoś z Twoich najbliższych
        <br /> padliście ofiarą oszustwa
      </p>
      <div
        className='mt-20 p-8 md:p-20 rounded-xl max-w-7xl mx-auto text-center'
        style={{
          backgroundImage: `url(${bcg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <p className='text-2xl text-white'>
          Zgłoś to na najbliższej Komendzie Policji,
          <br /> zadzwoń do swojego Dzielnicowego lub na numer alarmowy 112
        </p>
      </div>
    </div>
  );
}

export default SectionFour;
