import React from 'react';
import lombard from '../img/lom-logo.png';
import puls from '../img/puls-logo.png';
import poczta from '../img/poczta-logo.png';
import police from '../img/policja-logo.png';
import fundacja from '../img/fundacja-logo.png';
import zbp from '../img/zbp-logo.png';
import kgw from '../img/kgw-logo.png';
import fincert from '../img/fincert-logo.png';
import pkp from '../img/pkp-logo.png';
import eri from '../img/pz-eri-logo.png';
import gs from '../img/gs-logo.png';
import helios from '../img/helios-logo.png';
import przyjaciolka from '../img/przyjaciolka-logo.png';
import storad from '../img/100-logo.png';
import jetline from '../img/jetline-logo.png';
import eskumed from '../img/eskumed-logo.jpg';
import utw from '../img/utw-logo.png';
import radiopogoda from '../img/radio-pogoda-logo.png';
import cbzc from '../img/cbzc-logo.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { FaRegImage } from 'react-icons/fa';

function SwiperSlider() {
  return (
    <>
      <div className='hidden md:flex justify-end mb-8'>
        <p className='basis-7/12 text-[#ED6D28] text-center'>Organizatorzy</p>
        <p className='basis-2/12 text-[#ED6D28] '>Akcję wspierają</p>
        <p className='basis-3/12 text-[#ED6D28] '>Partnerzy merytoryczni</p>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={3}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 8,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <a href='https://tvpuls.pl' target='_blank' rel='noopener noreferrer'>
            <img src={puls} alt='logo puls' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://tvpuls.pl/seriale/lombard-zycie-pod-zastaw'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={lombard} alt='logo lombard' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://policja.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={police} alt='logo policja' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://fundacjapoddebem.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={fundacja} alt='logo fundacja' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://kolagospodynwiejskich.org/'
            target='_blank'
            rel='noopener noreferrer'
            className='hover:cursor-pointer'
          >
            <img src={kgw} alt='logo kgw' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://www.poczta-polska.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={poczta} alt='logo poczta' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://www.zbp.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={zbp} alt='logo zbp' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://zbp.pl/Dla-Bankow/Cyberbezpieczenstwo/Cyberbezpieczenstwo-bankow-i-ich-klientow'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={fincert} alt='logo fincert' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a href='https://pkp.pl' target='_blank' rel='noopener noreferrer'>
            <img src={pkp} alt='logo fincert' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='http://emeryci.milanowek.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={eri} alt='logo fincert' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://glosseniora.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={gs} alt='logo głos seniora' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://helios.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={helios} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://bauer.pl/czasopismo/przyjaciolka/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={przyjaciolka} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://bauer.pl/czasopismo/100-rad/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={storad} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://jetline.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={jetline} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://cbzc.policja.gov.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={cbzc} alt='logo cbzc' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://federacjautw.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={utw} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://radiopogoda.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={radiopogoda} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://www.facebook.com/people/Pogotowie-Medyczne-Eskumed/100034708226658/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={eskumed} alt='eskumed' className='block mx-auto' />
          </a>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SwiperSlider;
