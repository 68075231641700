import React from 'react';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';

function SocialMediaItems() {
  return (
    <>
      <div className='bg-[#ED6D28] w-10 h-10 flex items-center justify-center rounded-lg shadow-lg shadow-[#ED6D28]/20 '>
        <a
          href='https://www.instagram.com/telewizjapuls_official/'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaInstagram fill='#fff' size={24} />
        </a>
      </div>
      <div className='bg-[#ED6D28] w-10 h-10 flex items-center justify-center rounded-lg shadow-lg shadow-[#ED6D28]/20 mx-8'>
        <a
          href='https://www.facebook.com/tvpulspl'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaFacebook fill='#fff' size={24} />
        </a>
      </div>
      <div className='bg-[#ED6D28] w-10 h-10 flex items-center justify-center rounded-lg shadow-lg shadow-[#ED6D28]/20 '>
        <a
          href='https://www.tiktok.com/@tv_puls'
          target='_blank'
          rel='noopener noreferrer'
        >
          <FaTiktok fill='#fff' size={24} />
        </a>
      </div>
    </>
  );
}

export default SocialMediaItems;
