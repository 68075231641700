import React from 'react';
import lombard from '../img/lom-logo.png';
import puls from '../img/puls-logo.png';
import poczta from '../img/poczta-logo.png';
import police from '../img/policja-logo.png';
import fundacja from '../img/fundacja-logo.png';
import zbp from '../img/zbp-logo.png';
import kgw from '../img/kgw-logo.png';
import cbzc from '../img/cbzc-logo.png';
import fincert from '../img/fincert-logo.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

function SwiperSliderPartnerzy() {
  return (
    <>
      <div className='hidden md:flex justify-center my-8'>
        <p className='text-center text-[#ED6D28] '>Partnerzy merytoryczni</p>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={60}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <a
            href='https://www.zbp.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={zbp} alt='logo zbp' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://zbp.pl/Dla-Bankow/Cyberbezpieczenstwo/Cyberbezpieczenstwo-bankow-i-ich-klientow'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={fincert} alt='logo fincert' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://cbzc.policja.gov.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={cbzc} alt='logo cbzc' className='block mx-auto' />
          </a>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SwiperSliderPartnerzy;
