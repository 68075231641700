import React from 'react';
import bcg from '../img/bcg-2.jpg';
import imgTwo from '../img/img-2.png';
import YoutubeEmbed from './YoutubeEmbed';
import SwiperSlider from './SwiperSlider';
import GridSwiper from './GridSwiper';
import SwiperSliderOragnizatorzy from './SwiperSliderOrganizatorzy';
import SwiperSliderAkcjeWspiera from './SwiperSliderAkcjeWspiera';
import SwiperSliderPartnerzy from './SwiperSliderPartnerzy';

function SectionOne() {
  return (
    <div
      id='section-2'
      className='pt-12'
      style={{
        backgroundImage: `url(${bcg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className='container mx-auto flex flex-wrap justify-center items-center relative px-4'>
        <div className='lg:basis-2/4 flex items-end'>
          <img src={imgTwo} alt='beton' className='block mx-auto w-11/12' />
        </div>
        <div className='w-full lg:basis-2/4 md:pr-5 pb-10 2xl:pb-10 md:pb-[85px] -order-1 lg:order-1'>
          <p className='text-[#ED6D28] text-xl md:text-3xl my-4 text-center md:text-left'>
            Nie daj się nabrać!
          </p>
          <p className='text-5xl lg:text-7xl font-bold text-center md:text-left'>
            #Znam
            <br />
            TeNumery
          </p>

          <div className='h-2 bg-[#ED6D28] w-1/6 mt-8 mb-12 block mx-auto md:mx-0'></div>
          <YoutubeEmbed embedId='KUp_2EO9UpQ?si=A3mWrMkyc1wibnf-' />
        </div>
        {/* <div className='realtive lg:absolute -bottom-[50%] left-1/2 lg:-translate-x-1/2 bg-white max-w-7xl h-auto w-full rounded-xl shadow-2xl text-center py-8 shadow-[#ED6D28]/20 '> */}
        <div className='realtive lg:absolute -bottom-[600px] left-1/2 lg:-translate-x-1/2 bg-white max-w-7xl md:h-[700px] w-full rounded-xl shadow-2xl text-center py-8 shadow-[#ED6D28]/20 '>
          <div className='hidden md:block'>
            <SwiperSliderOragnizatorzy />
            <SwiperSliderAkcjeWspiera />
            <SwiperSliderPartnerzy />
          </div>
          <div className='block md:hidden'>
            <SwiperSlider />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOne;
