import React, { useRef } from 'react';
import logo from '../img/page-logo.png';
import SocialMediaLombard from './SocialMediaLombard';

function Footer() {
  return (
    <div>
      <div className='container mx-auto mt-20 mb-10 px-4'>
        <p className='text-[#ED6D28] text-center text-2xl'>
          Nie daj się nabrać!
        </p>
        <div className='md:flex justify-between mt-20'>
          <div>
            <img src={logo} alt='logo' className='w-48 md:w-64 mx-auto' />
          </div>
          <div className='mt-8 md:mt-0'>
            <p className='text-center md:text-right text-[#ED6D28]'>
              Informacje o akcji znajdziesz również
              <br /> w naszych SOCIAL MEDIACH
            </p>
            <div className='flex justify-center md:justify-end mt-4'>
              <SocialMediaLombard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
