import React from 'react';
import lombard from '../img/lom-logo.png';
import puls from '../img/puls-logo.png';
import poczta from '../img/poczta-logo.png';
import police from '../img/policja-logo.png';
import fundacja from '../img/fundacja-logo.png';
import zbp from '../img/zbp-logo.png';
import kgw from '../img/kgw-logo.png';
import fincert from '../img/fincert-logo.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

function SwiperSliderOragnizatorzy() {
  return (
    <>
      <div className='hidden md:flex justify-center mb-8'>
        <p className='text-center text-[#ED6D28]'>Organizatorzy</p>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={60}
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <a href='https://tvpuls.pl' target='_blank' rel='noopener noreferrer'>
            <img src={puls} alt='logo puls' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://tvpuls.pl/seriale/lombard-zycie-pod-zastaw'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={lombard} alt='logo lombard' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://policja.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={police} alt='logo policja' className='block mx-auto' />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <a
            href='https://fundacjapoddebem.pl/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img src={fundacja} alt='logo fundacja' className='block mx-auto' />
          </a>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SwiperSliderOragnizatorzy;
