import React from 'react';
import bcg from '../img/bcg-1-mod.jpg';
import imgOne from '../img/henryk-1.png';
import banner from '../img/image002.png';
import { FaAngleDown, FaExclamation } from 'react-icons/fa';
import ScrollIntoView from 'react-scroll-into-view';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';
import SocialMediaItems from './SocialMediaItems';
import YoutubeEmbed from './YoutubeEmbed';
import konkursEnd from '../img/komunikat_ztn.jpg';
import mobileEmerytura from '../img/mobile.jpg';

function Hero() {
  return (
    <div className='pb-28 px-4'>
      <div
        className='container mx-auto rounded-xl pt-10 m-5 flex flex-wrap justify-center relative '
        style={{
          backgroundImage: `url(${bcg})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className=' md:basis-2/4 flex items-end'>
          <img
            src={imgOne}
            alt='aktorzy'
            className='block mx-auto w-11/12 h-auto'
          />
        </div>
        <div className='block lg:hidden h-48 w-auto mt-2'>
          <img src={banner} alt='banner' className='h-full w-auto' />
        </div>
        <div className='-order-1 md:order-1 basis-1/1 md:basis-2/4 px-2 md:px-0 md:pr-5 pb-10'>
          <p className='text-[#ED6D28] text-2xl my-6 text-center md:text-left'>
            Nie daj się nabrać!
          </p>
          <p className='text-5xl 2xl:text-6xl font-bold text-center md:text-left'>
            Emerytura na maxa
            <br />
            nie istnieje!
          </p>
          <p className='text-[#ED6D28] text-2xl my-6 text-center md:text-left'>
            <span className='font-bold text-black'>#ZnamTeNumery!</span>{' '}
            Ogólnopolska kampania społeczna na rzecz bezpieczeństwa seniorów
          </p>
          <div className='h-2 bg-[#ED6D28] w-1/4 my-6 block mx-auto md:mx-0'></div>
          <p className='text-center md:text-left font-bold text-xl'>
            <span className='font-bold'>Poznaj sposoby,</span> jak chronić się
            przed <span className='text-[#ED6D28] font-bold'>scamami*</span>{' '}
            wykorzystującymi{' '}
            <span className='font-bold'>sztuczną inteligencję</span>
          </p>
          <div className=''>
            <div className='hidden lg:block h-48 w-auto mt-4'>
              <a
                href=' https://tvpuls.pl/seriale/lombard-zycie-pod-zastaw'
                target='_blank'
                nofollow='true'
                norefferer='true'
              >
                <img src={banner} alt='banner' className='h-full w-auto' />
              </a>
            </div>
            <ScrollIntoView selector='#kgw'>
              <button className='block mx-auto md:mx-0 mt-4 border-2 border-[#ED6D28] py-4 px-12 rounded-2xl'>
                Czytaj więcej
              </button>
            </ScrollIntoView>
          </div>
        </div>
        <div className='hidden absolute -bottom-8 left-1/2 -translate-x-1/2 bg-white w-16 h-16 md:flex items-center justify-center rounded-full'>
          <ScrollIntoView selector='#scam'>
            <div className='w-12 h-12 shadow-lg rounded-full flex items-center justify-center hover:translate-y-1 hover:cursor-pointer transition-all'>
              <FaAngleDown size={32} fill='#ED6D28' />
            </div>
          </ScrollIntoView>
        </div>
      </div>
      <div id='scam' className='max-w-7xl mx-auto pt-24'>
        <p className='text-center text-3xl md:text-5xl font-bold'>
          Emerytura na maxa nie istnieje!
        </p>
        <p className='text-center text-xl md:text-3xl mb-8 mt-2'>
          Nowa kampania Telewizji Puls wykorzystująca scam*
        </p>
        <div className='h-2 bg-[#ED6D28] w-1/3 md:w-1/6 mt-8 mb-12 block mx-auto'></div>
        <p className='text-right'>*wyjaśnienie w filmie</p>
        <YoutubeEmbed embedId='SRJCu64Mauw' />
      </div>
      <div
        id='kgw'
        className='mt-40 py-8 px-4 md:p-20 rounded-xl max-w-7xl mx-auto text-center'
      >
        <div className=''>
          <img
            src={konkursEnd}
            alt='konkurs'
            className='hidden md:block mx-auto w-full rounded-xl'
          />
          <img
            src={mobileEmerytura}
            alt='konkurs'
            className='block md:hidden mx-auto w-full rounded-xl'
          />
        </div>
      </div>
      <p id='numbers' className='text-center mt-24 text-[#ED6D28] text-xl my-6'>
        Liczby nie kłamią
      </p>
      <h2 className='text-center text-4xl font-bold'>
        Zobacz, jak wiele jest zagrożeń
      </h2>
      <div className='h-2 bg-[#ED6D28] w-40 my-6 block mx-auto'></div>

      <div className=' container mx-auto my-10 grid md:grid-cols-3 gap-4 px-4'>
        <div className='basis-1/3 py-16 px-9 bg-white shadow-xl rounded-lg'>
          <div className='bg-[#ED6D28] w-20 h-20 flex items-center justify-center rounded-lg shadow-lg shadow-[#ED6D28]/50 mb-4'>
            <div className='p-2 rounded-full border-2'>
              <FaExclamation size={32} fill='#fff' />
            </div>
          </div>
          <p className='mt-6'>Ponad</p>
          <p className='mb-4 text-3xl font-bold'>139 mln zł</p>
          <p>
            wartość strat finansowych poniesionych w 2023 roku na skutek
            przestępstw metodą działania sprawcy „na wnuczka”, „na urzędnika”.
          </p>
        </div>

        <div className='basis-1/3 py-16 px-9 bg-white shadow-xl rounded-lg'>
          <div className='bg-[#ED6D28] w-20 h-20 flex items-center justify-center rounded-lg shadow-lg shadow-[#ED6D28]/50 mb-4'>
            <div className='p-2 rounded-full border-2'>
              <FaExclamation size={32} fill='#fff' />
            </div>
          </div>
          <p className='mt-6'>Ponad</p>
          <p className='mb-4 text-3xl font-bold'>13 000</p>
          <p>
            liczba osób pokrzywdzonych, w wieku 65 i więcej przestępstwem
            oszustwa.
          </p>
        </div>

        <div className='basis-1/3 py-16 px-9 bg-white shadow-xl rounded-lg'>
          <div className='bg-[#ED6D28] w-20 h-20 flex items-center justify-center rounded-lg shadow-lg shadow-[#ED6D28]/50 mb-4'>
            <div className='p-2 rounded-full border-2'>
              <FaExclamation size={32} fill='#fff' />
            </div>
          </div>
          <p className='mt-6'>Ponad</p>
          <p className='mb-4 text-3xl font-bold'>3 300</p>
          <p>
            liczba osób pokrzywdzonych w wieku 65+ metodą działania sprawcy „na
            wnuczka”, „na urzędnika” w 2023 roku.
          </p>
        </div>
      </div>
      <p className='text-center text-xs'>
        Źródło: dane statystyczne z Krajowego Systemu Informacyjnego Policji
        (KSIP).
      </p>

      <div className='mt-32 text-2xl md:text-3xl text-center'>
        <p>
          Sprawdź nasze <span className='uppercase'>social media</span>
        </p>
        <div className='flex justify-center mt-6'>
          <SocialMediaItems />
        </div>
      </div>
    </div>
  );
}

export default Hero;
