import React from 'react';
import img from '../img/img-3.png';
import CommentsSlider from './CommentsSlider';
import SocialMediaLombard from './SocialMediaLombard';

function SectionTwo() {
  return (
    <div
      className='pt-50 md:pt-[700px]'
      style={{
        // backgroundImage: `url(${bcg})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {' '}
      <div
        id='two'
        className='container mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 relative px-4'
      >
        <div className='pr-5 pb-10'>
          <p className='text-[#ED6D28] text-xl text-center lg:text-left mb-1'>
            Dowiedz się więcej
          </p>
          <p className='text-3xl lg:text-4xl text-center lg:text-left mb-1'>
            O akcji
          </p>
          <p className='text-5xl lg:text-7xl text-center lg:text-left font-bold'>
            #Znam
            <br />
            TeNumery
          </p>

          <div className='h-2 bg-[#ED6D28] w-1/6 mt-8 mb-12 block mx-auto lg:mx-0'></div>
          <p>
            Czy wiesz, że w ciągu 2022 roku seniorzy stali się celem ponad 12
            000 prób oszustwa? Niesławna metoda „na wnuczka”, podszywanie się
            pod pracownika banku czy urzędnika to tylko niektóre sposoby, aby
            wykorzystać łatwowierność osób starszych.
          </p>
          <p className='mt-3'>
            Kampania #ZnamTeNumery ma na celu edukację osób starszych i ich
            bliskich oraz zwrócenie uwagi na grożące im niebezpieczeństwa.
            Promują ją aktorzy z „Lombardu” – Henryk Gołębiewski, Małgorzata
            Szeptycka i Dominik Dąbrowski. Partnerem kampanii jest Poczta
            Polska.
          </p>
          <CommentsSlider />
          <p className='text-[#ED6D28] text-center md:text-left'>
            SOCIAL MEDIA serialu „Lombard”
          </p>
          <div className='flex justify-center md:justify-start mt-4'>
            <SocialMediaLombard />
          </div>
        </div>

        <div className='flex items-end'>
          <img src={img} alt='ziutek' className='block mx-auto w-11/12' />
        </div>
      </div>
    </div>
  );
}

export default SectionTwo;
