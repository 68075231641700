import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';
import GridSwiper from './GridSwiper';

function SectionFive() {
  return (
    <div className='bg-[#FDF7F3]'>
      <div className='container mx-auto py-40 px-4'>
        <p className='text-center md:text-left text-5xl lg:text-7xl font-bold block w-fit mx-auto'>
          #Znam
          <br />
          TeNumery
        </p>
        <div className='h-2 bg-[#ED6D28] w-1/6 mt-8 mb-12 block mx-auto'></div>
        <p className='text-center'>
          Aktorzy serialu „Lombard. Życie pod zastaw” uczą i przestrzegają:
        </p>
        <div className='mt-10'>
          <GridSwiper />
        </div>
      </div>
    </div>
  );
}

export default SectionFive;
