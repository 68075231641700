import React, { useEffect, useState } from 'react';
import YoutubeEmbed from './YoutubeEmbed';
import ScrollIntoView from 'react-scroll-into-view';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import stop from '../img/stop.png';

function PopUp() {
  const [active, setActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentDate = new Date();
    const targetDate = new Date('2024-11-12');
    const isFromEmeryturaNaMaxa =
      document.referrer.includes('emeryturanamaxa.pl');
    const params = new URLSearchParams(window.location.search);
    const showPopup = params.get('popup');

    if (showPopup === 'emerytura' && currentDate >= targetDate) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }

    const timer = setTimeout(() => {
      setActive(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    setActive(false);
  };

  return (
    isVisible &&
    active && (
      <div className='fixed top-0 left-0 z-[500] h-screen w-screen flex justify-center items-center bg-black/75'>
        <div className='max-w-screen-md mx-auto bg-[#FCF7F3] p-8 rounded-xl relative max-h-screen overflow-y-auto'>
          <p className='absolute top-2 right-2'>
            <IoIosCloseCircleOutline
              size={32}
              color={'#999'}
              className='hover:cursor-pointer hover:opacity-70'
              onClick={handleClick}
            />
          </p>

          <div className='flex flex-wrap items-center justify-center'>
            <div className='lg:basis-2/12 mb-4 lg:mb-0'>
              <img src={stop} alt='stop' className='h-24 w-auto' />
            </div>
            <p className='lg:basis-10/12 text-sm'>
              Właśnie przez <span className='font-bold'>jedno kliknięcie</span>{' '}
              mogłeś <span className='font-bold'>stracić oszczędności</span>{' '}
              swojego życia! Oszuści mogliby zainstalować na Twoim komputerze
              lub smartfonie oprogramowanie, które{' '}
              <span className='font-bold'>
                wyczyściłoby wszystkie Twoje konta
              </span>{' '}
              lub pozyskałoby inne cenne dane!
            </p>
          </div>
          <p className='text-3xl text-center my-4'>
            <span className='text-[#ED6C28] font-bold'>Emerytura na maxa</span>{' '}
            nie istnieje!
          </p>
          <div className='border-b-2 border-[#ED6C28] max-w-sm block mx-auto'></div>
          <p className='text-lg text-center mt-4'>
            Zobacz, co chce Ci powiedzieć PRAWDZIWY
            <br /> <span className='font-bold'>Henryk Gołębiewski:</span>
          </p>
          <div className='lg:px-8 my-4'>
            <YoutubeEmbed embedId='SRJCu64Mauw' />
          </div>
          <div className='flex justify-center'>
            <ScrollIntoView selector='#kgw'>
              <button
                className='block mx-auto md:mx-0 mt-4 border-2 border-[#ED6D28] py-4 px-12 rounded-2xl'
                onClick={handleClick}
              >
                Czytaj więcej
              </button>
            </ScrollIntoView>
          </div>
        </div>
      </div>
    )
  );
}

export default PopUp;
