import React, { useEffect, useState } from 'react';
import img from '../img/img-4.png';

import { IoIosCloseCircle } from 'react-icons/io';
import {
  FaAppStoreIos,
  FaApple,
  FaGooglePlay,
  FaSmileBeam,
} from 'react-icons/fa';

function SectionThree() {
  const [isActive, setIsActive] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    five: false,
    six: false,
    seven: false,
    eight: false,
  });

  useEffect(() => {
    const html = document.querySelector('html');

    html.style.overflow =
      isActive.one ||
      isActive.two ||
      isActive.three ||
      isActive.four ||
      isActive.five ||
      isActive.six ||
      isActive.seven ||
      isActive.eight
        ? 'hidden'
        : 'auto';
  }, [isActive]);
  return (
    <div className='pt-12 bg-[#FDF7F3]'>
      <div className='container mx-auto flex flex-wrap justify-center items-center relative px-4 '>
        <div className='md:basis-2/4 flex items-end col-before'>
          <img
            src={img}
            alt='zona kazimerza'
            className='block mx-auto w-11/12'
          />
        </div>
        <div className='md:basis-2/4 pr-5 pb-10 -order-1 md:order-1'>
          <p className='text-[#ED6D28] text-xl my-4'>Musisz to wiedzieć</p>
          <p className='text-4xl lg:text-6xl font-bold'>Nie daj się nabrać!</p>

          <div className='h-2 bg-[#ED6D28] w-1/6 mt-8 mb-12'></div>
          <p>
            Pamiętaj! Stosuj codziennie listę prostych zasad, które mogą
            uchronić Ciebie i Twoich bliskich przed oszustwem.
          </p>
          <ul className='mt-10  ml-16 list'>
            <li className='my-4'>
              Ustal z najbliższymi hasło – klucz.{' '}
              <span
                className='text-[#ED6D28] text-sm  hover:cursor-pointer'
                onClick={() => setIsActive({ one: true })}
              >
                Czytaj więcej
              </span>
              {isActive.one && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8  hover:cursor-pointer'
                    onClick={() => setIsActive({ one: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Ustal z najbliższymi hasło-klucz
                  </p>
                  <p>
                    Każdy z nas ma wspomnienia, o których wiedzą tylko nasi
                    najbliżsi. Imię pierwszego psa, ulubiona piosenka dziadka
                    Władka, kolor pierwszego samochodu. Umówcie się z
                    rodziną/przyjaciółmi/sąsiadami na takie hasło, które znacie
                    tylko Wy, i wszyscy je zapamiętajcie. W razie rozmowy z
                    fałszywym „wnuczkiem” szybko zweryfikujecie, czy faktycznie
                    jest tym, za kogo się podaje.
                  </p>
                </div>
              )}
            </li>
            <li className='my-4'>
              Nie ufaj – sprawdzaj z kim rozmawiasz!{' '}
              <span
                className='text-[#ED6D28] text-sm  hover:cursor-pointer'
                onClick={() => setIsActive({ two: true })}
              >
                Czytaj więcej
              </span>
              {isActive.two && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8 hover:cursor-pointer'
                    onClick={() => setIsActive({ two: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Nie ufaj – sprawdzaj z kim rozmawiasz
                  </p>
                  <p>
                    Wyobraź sobie taką sytuację: dzwoni telefon, podnosisz
                    słuchawkę. Osoba, z którą rozmawiasz przekonuje, że jest
                    Twoim krewnym lub jego znajomym i informuje, że pilnie
                    potrzebuje pieniędzy.  Telefon dzwoni ponownie. Dzwoniąca
                    nowa Osoba informuje Cię, że „jest policjantem” i
                    przekonuje, że przed chwilą dzwonił oszust, a Ty musisz
                    pomóc go zatrzymać. Zostajesz poinformowany, że potrzebne są
                    pieniądze, które masz przekazać policjantowi. Co robisz?
                    Natychmiast rozłącz się, odczekaj minimum 30 sekund, a jeśli
                    masz taką możliwość zadzwoń z innego numeru na 112.
                    Koniecznie wybierz numer na klawiaturze numerycznej, nie
                    oddzwaniaj z listy połączeń, które wyświetlają się na
                    telefonie.{' '}
                    <span className='font-bold'>
                      PAMIETAJ! Policja nigdy nie prosi o przekazanie pieniędzy
                      i nigdy nie dzwoni z takim żądaniem!
                    </span>
                  </p>
                </div>
              )}
            </li>
            <li className='my-4'>
              Nie klikaj w linki, nie odpowiadaj na nieznane SMS-y!{' '}
              <span
                className='text-[#ED6D28] text-sm  hover:cursor-pointer'
                onClick={() => setIsActive({ three: true })}
              >
                Czytaj więcej
              </span>
              {isActive.three && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8  hover:cursor-pointer'
                    onClick={() => setIsActive({ three: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Nie klikaj w linki, nie odpowiadaj na nieznane SMSy
                  </p>
                  <p>
                    Jeśli otrzymałeś SMS-a lub maila od nieznanego nadawcy z
                    linkiem, nigdy w go nie klikaj. Adresy fałszywych stron od
                    oryginałów różnią się nieraz minimalnie – możemy je
                    przeczytać kilka razy i nie zauważymy zmian. Część tych
                    wiadomości (linków) może być próbą wyłudzenia ważnych
                    informacji lub zainfekowania naszego telefonu lub komputera
                    złośliwym oprogramowaniem – wirusem. Jeśli masz wątpliwości,
                    poradź się rodziny i znajomych.
                  </p>
                </div>
              )}
            </li>
            <li className='my-4'>
              Nie podawaj żadnych numerów, loginów i haseł!{' '}
              <span
                className='text-[#ED6D28] text-sm hover:cursor-pointer'
                onClick={() => setIsActive({ four: true })}
              >
                Czytaj więcej
              </span>
              {isActive.four && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8  hover:cursor-pointer'
                    onClick={() => setIsActive({ four: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Nie podawaj żadnych numerów, loginów i haseł!
                  </p>
                  <p>
                    Zapamiętaj! Żaden pracownik banku, policji, poczty, ZUS-u,
                    Urzędu Skarbowego, ubezpieczyciel, lekarz, inkasent,
                    gazownik czy ksiądz nigdy nie poprosi Cię o loginy, hasła
                    czy numery PIN do konta.  Nie ma takiego prawa. Koniec i
                    kropka. To samo dotyczy rzekomych znajomych syna, córki czy
                    wnuczka. Pamiętaj nigdy nie zapisuj loginów i haseł w
                    widocznym i łatwo dostępnym miejscu, chroń je z najwyższą
                    starannością.
                  </p>
                </div>
              )}
            </li>
            <li className='my-4'>
              Nowa znajomość? – super, ale bądź czujny!{' '}
              <span
                className='text-[#ED6D28] text-sm hover:cursor-pointer'
                onClick={() => setIsActive({ five: true })}
              >
                Czytaj więcej
              </span>
              {isActive.five && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8  hover:cursor-pointer'
                    onClick={() => setIsActive({ five: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Nowa znajomość? – super, ale bądź czujny
                  </p>
                  <p>
                    Na wakacjach, w sanatorium, w Domu Kultury, w kościele, w
                    Internecie … wszędzie możemy spotkać nowych znajomych,
                    przyjaciół czy nawet drugą połówkę. I to jest piękne! Jeśli
                    jednak nowo poznana Osoba po kilku tygodniach znajomości
                    prosi Cię o sporą pożyczkę, spłatę długu, pieniądze na
                    bilet, zastanów się dwa razy, zanim powierzysz jej
                    oszczędności życia, lub poradź się bliskich. Lubi? Kocha? To
                    zrozumie i zaakceptuje odmowę.
                  </p>
                </div>
              )}
            </li>
            <li className='my-4'>
              Stwórz Listę Wsparcia – kontaktów do osób, którym ufasz.{' '}
              <span
                className='text-[#ED6D28] text-sm hover:cursor-pointer'
                onClick={() => setIsActive({ six: true })}
              >
                Czytaj więcej
              </span>
              {isActive.six && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8  hover:cursor-pointer'
                    onClick={() => setIsActive({ six: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Stwórz Listę Wsparcia najbliższych osób, którym ufasz
                  </p>
                  <p>
                    Miej zawsze pod ręką, np. w domu na kartce na lodówce,
                    numery telefonu do najbliższych Osób, którym ufasz, w tym do
                    swojego Dzielnicowego. W razie jakichkolwiek wątpliwości to
                    oni Ci pomogą.
                  </p>
                </div>
              )}
            </li>
            <li className='my-4'>
              Nie wierz obcemu, że Wasza rozmowa jest poufna.{' '}
              <span
                className='text-[#ED6D28] text-sm hover:cursor-pointer'
                onClick={() => setIsActive({ seven: true })}
              >
                Czytaj więcej
              </span>
              {isActive.seven && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8  hover:cursor-pointer'
                    onClick={() => setIsActive({ seven: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Nie wierz obcemu, że Wasza rozmowa jest poufna
                  </p>
                  <p>
                    Życie to nie film szpiegowski, a Ty nie jesteś tajnym
                    agentem. Żaden urzędnik, pracownik banku, ubezpieczyciel czy
                    policjant nigdy nie poprosi o zachowanie w tajemnicy Waszej
                    rozmowy! To samo dotyczy rzekomych znajomych syna, córki czy
                    wnuczka. Przeciwnie: każda prośba Nieznajomego o
                    nieinformowanie rodziny czy bliskich o Waszej rozmowie
                    powinna natychmiast wzbudzić Twoją czujność!
                  </p>
                </div>
              )}
            </li>
            <li className='my-4'>
              Poznaj swojego Dzielnicowego.{' '}
              <span
                className='text-[#ED6D28] text-sm hover:cursor-pointer'
                onClick={() => setIsActive({ eight: true })}
              >
                Pobierz aplikacje Twój Dzielnicowy
              </span>
              {isActive.eight && (
                <div className='fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  bg-white px-8 py-12 z-30 rounded-xl shadow-2xl overflow-auto w-full h-screen lg:w-auto lg:h-auto max-h-screen'>
                  <IoIosCloseCircle
                    size={32}
                    fill='#ED6D28'
                    className='absolute top-4 right-8  hover:cursor-pointer'
                    onClick={() => setIsActive({ eight: false })}
                  />
                  <p className='font-bold mb-2'>
                    {' '}
                    Poznaj swojego Dzielnicowego
                  </p>
                  <p>
                    Telefon do swojego Dzielnicowego sprawdzisz dzięki aplikacji
                    mobilnej Moja Komenda lub na stronach internetowych Twojej
                    lub interesującej Cię jednostki Policji. Pamiętaj! Możesz
                    zawsze zadzwonić do swojego Dzielnicowego i się poradzić,
                    jeśli Twój niepokój wzbudzi podejrzany telefon bądź wizyta.
                    <span className='font-bold'>
                      Dzielnicowi nie pełnią jednak służby całodobowo. W
                      sytuacjach nagłych lub zagrażających bezpośrednio życiu i
                      zdrowiu należy korzystać z numerów alarmowych 112 lub 997.
                    </span>
                  </p>
                  <p className='mt-2'>
                    W ramach programu „Dzielnicowy bliżej nas” opracowano i
                    wdrożono do powszechnego użytku aplikację „Moja Komenda”.
                    Jest to aplikacja bezpłatna opracowana na urządzenia mobilne
                    bazujące na systemach operacyjnych Android oraz iOS.
                    Aplikacja, po jej zainstalowaniu, może pracować w trybie
                    off-line, jednak jej pełne funkcjonalności są widoczne przy
                    zapewnieniu dostępu do sieci Internet. Aplikacja została
                    wyposażona w dwa tryby wyszukiwania informacji o jednostkach
                    Policji, jak i dzielnicowych. Po włączeniu usług
                    lokalizacyjnych w telefonie aplikacja sama odnajdzie
                    najbliższy policyjny obiekt, wskaże trasę dojścia lub
                    dojazdu. <span className='font-bold'>„Moja Komenda”</span>{' '}
                    umożliwia również wyszukanie po nazwie jednostki, ulicy lub
                    kodzie pocztowym oraz podgląd siedziby jednostki w trybie
                    „Street view”. Dane nawigacyjne oraz „Street view”
                    aktualizowane są przez operatorów zewnętrznych.{' '}
                  </p>
                  <p className='font-bold mt-2'>
                    Wystarczy, że wprowadzisz miejscowość, nazwę ulicy i numer
                    posesji, a aplikacja wyszuka dzielnicowego, który dba o ten
                    rejon. Dla ułatwienia obsługi, w przypadku małych
                    miejscowości, wystarczy wpisać samą nazwę miejscowości.
                    Dzięki aplikacji można odnaleźć najbliższą jednostkę
                    Policji, sprawdzić jak nazywa się dzielnicowy, uzyskać pełne
                    dane teleadresowe i połączyć się z nim jednym kliknięciem,
                    jak też wysłać wiadomość e-mail.
                  </p>
                  <p className='mt-8 text-center'>
                    Aplikację pobrać można pod poniższym adresem:{' '}
                  </p>
                  <div className='md:flex justify-center mt-4'>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.altconnect.android.mk&hl=pl '
                      target='_blank'
                      className='mx-auto mb-4 md:mx-4 bg-black  flex items-center justify-center p-4 rounded-lg w-fit '
                    >
                      <FaGooglePlay size={40} fill='#fff' />
                      <p className='text-white ml-2'>
                        Pobierz z <br />
                        Google Play
                      </p>
                    </a>
                    <a
                      href=' https://itunes.apple.com/pl/app/moja-komenda/id919504159?l=pl&mt=8'
                      target='_blank'
                      className='mx-auto mb-4 md:mx-4 bg-black  flex items-center justify-center p-4 rounded-lg w-fit'
                    >
                      <FaApple size={40} fill='#fff' />
                      <p className='text-white ml-2'>
                        Pobierz z <br />
                        App Store
                      </p>
                    </a>
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SectionThree;
