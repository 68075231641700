import React from 'react';
import lombard from '../img/lom-logo.png';
import puls from '../img/puls-logo.png';
import poczta from '../img/poczta-logo.png';
import police from '../img/policja-logo.png';
import fundacja from '../img/fundacja-logo.png';
import zbp from '../img/zbp-logo.png';
import kgw from '../img/kgw-logo.png';
import pkp from '../img/pkp-logo.png';
import eri from '../img/pz-eri-logo.png';
import fincert from '../img/fincert-logo.png';
import gs from '../img/gs-logo.png';
import radiopogoda from '../img/radio-pogoda-logo.png';
import utw from '../img/utw-logo.png';
import eskumed from '../img/eskumed-logo.jpg';
import cbzc from '../img/cbzc-logo.png';
import helios from '../img/helios-logo.png';
import przyjaciolka from '../img/przyjaciolka-logo.png';
import storad from '../img/100-logo.png';
import jetline from '../img/jetline-logo.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

function SwiperSliderLogo() {
  return (
    <>
      <Swiper
        modules={[Navigation, Autoplay]}
        spaceBetween={10}
        slidesPerView={3}
        className='md:flex-1 -order-1 md:order-1 mb-4 md:mb-0'
        speed={3000}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }}
      >
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://tvpuls.pl'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={puls} alt='logo puls' className='block mx-auto' />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://tvpuls.pl/seriale/lombard-zycie-pod-zastaw'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={lombard} alt='logo lombard' className='block mx-auto' />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://policja.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={police} alt='logo policja' className='block mx-auto' />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://fundacjapoddebem.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={fundacja}
                alt='logo fundacja'
                className='block mx-auto'
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://kolagospodynwiejskich.org/'
              target='_blank'
              rel='noopener noreferrer'
              className='hover:cursor-pointer'
            >
              <img src={kgw} alt='logo kgw' className='block mx-auto' />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://www.poczta-polska.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={poczta} alt='logo poczta' className='block mx-auto' />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://www.zbp.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={zbp} alt='logo zbp' className='block mx-auto' />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://zbp.pl/Dla-Bankow/Cyberbezpieczenstwo/Cyberbezpieczenstwo-bankow-i-ich-klientow'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={fincert} alt='logo fincert' className='block mx-auto' />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a href='https://pkp.pl' target='_blank' rel='noopener noreferrer'>
              <img
                src={pkp}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='http://emeryci.milanowek.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={eri}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://glosseniora.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={gs}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://www.facebook.com/people/Pogotowie-Medyczne-Eskumed/100034708226658/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={eskumed}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://radiopogoda.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={radiopogoda}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://federacjautw.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={utw}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://cbzc.policja.gov.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={cbzc}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://jetline.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={jetline}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://bauer.pl/czasopismo/100-rad/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={storad}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://bauer.pl/czasopismo/przyjaciolka/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={przyjaciolka}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='flex items-center h-full'>
            <a
              href='https://helios.pl/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src={helios}
                alt='logo puls'
                className='block mx-auto w-16 md:w-24 '
              />
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default SwiperSliderLogo;
