// Import Swiper React components
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Grid, Pagination, Navigation } from 'swiper/modules';
import YoutubeEmbed from './YoutubeEmbed';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

function YtSwiper() {
  return (
    <>
      <Swiper
        modules={[Grid, Pagination, Navigation]}
        slidesPerView={1}
        grid={{
          rows: 1,
          fill: 'row',
        }}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className='gridSwiper'
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
            grid: {
              rows: 1,
              fill: 'row',
            },
          },
        }}
      >
        <SwiperSlide>
          {' '}
          <YoutubeEmbed embedId='oBQV6UmApEE?si=bm7HT_Iy2dzl8nhz' />
        </SwiperSlide>
        <SwiperSlide>
          {' '}
          <YoutubeEmbed embedId='Ol6fRG1EhA4?si=CaMtHViljtvsTThw' />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default YtSwiper;
